import { Metadata } from '../types/metadata.type';
import { GeoLocationMicrolink } from '../types/microlink.type';

export function transformMicrolinkToMetadata(
  geoLocation: GeoLocationMicrolink | null,
): Metadata {
  return {
    zip: geoLocation?.city?.postalCode,
    timezone: geoLocation?.timezone,
    region: geoLocation?.city?.alpha2,
    country_code: geoLocation?.country?.alpha2,
    latitude: geoLocation?.coordinates?.latitude,
    longitude: geoLocation?.coordinates?.longitude,
    ip: geoLocation?.ip?.address,
    city: geoLocation?.city?.name,
    country: geoLocation?.country?.name,
    continent: geoLocation?.continent?.name,
  };
}
