import { IpapiResponse } from '../types/ipapi.type';
import { Metadata } from '../types/metadata.type';

export function transformIpapiResponse(
  response: IpapiResponse | null,
): Metadata {
  return {
    zip: response?.postal,
    timezone: response?.timezone,
    region: response?.region,
    organization_name: response?.org,
    country_code: response?.country_code,
    organization: response?.asn,
    latitude: response?.latitude.toString(),
    longitude: response?.longitude.toString(),
    ip: response?.ip,
    city: response?.city,
    country: response?.country_name,
    continent: response?.continent_code,
  };
}
